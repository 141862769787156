
  .cards{
    border: none;
  }
  


  .headerboxes{
    overflow: hidden; 
    display: flex; 
    /* -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  */
    padding:8px;
    /* color:#333; 
    padding-top:10px; */
    min-width:10px;
    height:75px;
    background-color: #fed136;
    text-align: center;
    align-items: center;
    justify-content: center;
    /* background:linear-gradient(45deg, #bc60bc, #CC99CC); 
    background-size: 400% 400%;
    animation: newgradient 10s ease infinite; */
  }
  /* @keyframes newgradient {
      0% {
          background-position: 0% 100%;
      }
      100% {
          background-position: 100% 0%;
      }
  } */
  
  .iconBoxes{
    margin: auto;
    background:linear-gradient(45deg, #fed136 0%,#fed136 100%);
    width: 100px;
    height: 100px;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 3%;
    box-shadow:none;
    text-align:center;
    /* transform: rotateY(25deg) ; */
    animation: move 10s infinite;
  }
  
  @keyframes move {
    50% {transform: rotateY(180deg);}
  }
  
  .descBoxes{
    overflow: hidden; 
    display: -webkit-box; 
    -webkit-line-clamp: 2;
    height:200px;
    /* margin-top:-8px; */
    justify-content:center;
    /* background:linear-gradient(135deg, #fff 85%, #CC99CC 15% ); */
   -webkit-box-orient: vertical; 
   color:#333;
   padding:10px;
   /* padding-bottom:25px; */
   font-size:1rem;
   line-height: 1em;
  
  }