  .app-backgrounds {
    /* background-color: #bee3ff; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
  }
  
  .main-containers {
    background-color: #fff;
    width: 100%;
    height: min-content;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 10px 10px 26px 0px rgba(0, 0, 0, 0.35);
  }
  
  .titles {
    text-align: center;
  }
  
  .add-item-boxs {
    background: #CC99CC;
    /* color: #ec645b; */
    color: #660066;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
  }
  
  .add-item-boxs svg {
    margin: 5px;
  }
  
  .add-item-inputs {
    box-sizing: border-box;
    border: none;
    background: transparent;
    color: #fff;
    width: 100%;
    height: 30px;
  }
  
  input::placeholder {
    color: #660066;
  }
  input:focus {
    outline: 0;
  }
  
  .item-lists {
    display: flex;
    flex-direction: column;
  }
  
  .item-containers {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    padding: 20px 0 20px 0;
    border-bottom: 1px #fbc1bb solid;
  }
  
  .item-names {
    cursor: pointer;
  }
  
  .item-names svg {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  /* .item-action-buttons {
    width: 30%; 
    display: flex;
    justify-content: space-between;
  } */
  
  .remove-buttons {
    color: white;
    background-color: rgb(255, 0, 208);
  }
  
  .totals {
    float: right;
    padding: 10px;
  }
  
  .quantitys {
    /* display: flex; */
    align-items: center;
    border: 1px solid #ec645b;
    background: white;
    border-radius: 50px;
    font-size: 12px;
    color: #ec645b;
    /* min-width: 70px; */
    /* justify-content: space-between; */
    float: right;
    /* margin-left: 10px; */
  }
  
  button {
    background: transparent;
    border: none;
    margin: 3px;
    color: #4085ca;
  }
  button:focus {
    outline: 0;
  }
  
  button:hover {
    cursor: pointer;
  }
  
  .completed {
    text-decoration: line-through;
  }


  .input-filed {
    background: #fbc1bb;
    color: #ec645b;
    
  }