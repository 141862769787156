:root {
  --surface-color: #000;
  --curve: 40;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-top-big {
  margin-top: 5rem !important;
}



body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background: #000;
}



/* html {
  font-size: 100%;
} */


.card {
  /* -webkit-perspective: 150rem;
          perspective: 150rem;
  -moz-perspective: 150rem; */
  position: relative;
  height: 18rem;
  /* width:100%; */
  /*28rem*/
  /* padding-left: 5%; */
  /* vertical-align: baseline; */
  /* display:flex; */
  /* flex-wrap: nowrap; */
}

.card__side {
  height: 18rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  /* position: relative; */
  top: 0;
  left: 0;
  /* width: 100%; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  /* -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15); */
  /**/
  /* margin-left: 5%; */

}

.card__side--front {

  background-color: #fff;
  /* background-image: url('../assets/svg/ProcessImg.png'); */
  position: relative;
  width: auto;
  /* width: 18rem; */
  /* margin-right: 5%; */

}

.card__side--front-1 {
  background: linear-gradient(-45deg, #fff 15%, #fff 85%);
  width: auto;
  /* width: 18rem; */
}

/* .card__side--front-1 {
  background: linear-gradient(-45deg, rgb(139, 175, 175) 0%, rgb(32,178,170) 100%);
} */
/* .card__side--front-1 {
  background: linear-gradient(-45deg, #64b5f6, #f403d1);
} */

/* .card__side--front-2 {
  background: linear-gradient(-45deg, #f321d7, #ffec61);
}

.card__side--front-3 {
  background: linear-gradient(-45deg, #24ff72, #9a4eff);
} */

.card__side--back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  position: absolute;
  width:100%;
  /* width: 18rem; */
  /* MARGIN-left: 5%; */

}

.card__side--back-1 {
  background: linear-gradient(45deg, #CC99CC 0%, #a017a0 100%);
  /* background:repeating-radial-gradient(
      circle at 0 0, 
      #CC99CC,
      #fa9bfa 80px
    ); */
  /* background-image: 
    repeating-linear-gradient(
      -45deg,
      #CC99CC,
      #CC99CC 10px,
      #fa9bfa 10px,
      #fa9bfa 10px 
    ); */
}

/* .card__side--back-1 {
  background: linear-gradient(-45deg, #64b5f6, #f403d1);
} */

/* .card__side--back-2 {
  background: linear-gradient(-45deg, #ffec61, #f321d7);
}

.card__side--back-3 {
  background: linear-gradient(-45deg, #9a4eff, #24ff72);
} */

.card:hover .card__side--front-1,
.card:hover .card__side--front-2,
.card:hover .card__side--front-3 {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
  /* position: absolute; */
}


.card__cta {
  position: relative;
  /* top: 50%; */
  /* left: 50%; */
  /* -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); 
  width: 100%;
  align-items: center;
  justify-content: center; */
}

.col-1-of-3 {
  max-width: 114rem;
  margin: 0 auto;

}

.horizontalLine {
  width: 90%; 
  margin-top:21px; 
  background:linear-gradient(45deg, #CC99CC, #660066); 
  /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
  height:2px;
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.headerBox{
  overflow: hidden; 
  display: flex; 
  /* -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  */
  padding:8px;
  /* color:#333; 
  padding-top:10px; */
  min-width:10px;
  height:75px;
  background-color: #660066b8;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* background:linear-gradient(45deg, #bc60bc, #CC99CC); 
  background-size: 400% 400%;
  animation: newgradient 10s ease infinite; */
}
/* @keyframes newgradient {
	0% {
		background-position: 0% 100%;
	}
	100% {
		background-position: 100% 0%;
	}
} */

.iconBox{
  margin: auto;
  background:linear-gradient(45deg, #CC99CC 0%,#a017a0 100%);
  width: 100px;
  height: 100px;
  z-index: 1;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 3%;
  box-shadow:none;
  text-align:center;
  /* transform: rotateY(25deg) ; */
  animation: move 10s infinite;
}

@keyframes move {
  50% {transform: rotateY(180deg);}
}

.descBox{
  overflow: hidden; 
  display: -webkit-box; 
  -webkit-line-clamp: 2;
  height:64px;
  /* margin-top:-8px; */
  justify-content:center;
  /* background:linear-gradient(135deg, #fff 85%, #CC99CC 15% ); */
 -webkit-box-orient: vertical; 
 color:#333;
 padding:10px;
 /* padding-bottom:25px; */
 font-size:1rem;

}


.launchButton{
filter: blur(5px);
-webkit-filter: blur(5px);
}

.ag-theme-alpine{

  --ag-foreground-color: #660066;
  --ag-background-color: #fff;
  --ag-header-foreground-color: #fff;
  --ag-header-background-color: #660066;
  --ag-odd-row-background-color: rgba(204,153,204,0.4) ;
  /* --ag-header-column-resize-handle-color: #000; */
  --ag-border-color:#000;
  --ag-font-size: 1.5;
  --ag-font-family: "Lato", sans-serif;
  --ag-alpine-active-color: #fff;
  /* --ag-secondary-foreground-color: #fff; */
  /* --ag-data-color: var(--ag-background-color)
  --ag-cell-horizontal-border: solid transparent; */
  /* --ag-control-panel-background-color: #cc99cc; */
  /* --ag-subheader-background-color: #fff; */
  /* --ag-range-selection-border-color: #cc99cc; */
  --ag-data-color:#000;








}
.ag-theme-alpine .ag-standard-button.ag-filter-apply-panel-button{
  color: #fff;
  background-color: #660066;
}
.ag-header-icon {
  color: lightgray;}
.dividerStyle{
border-color: #fff;
color: #fff;
background-color: #fff;

}

.css-1l6rwmg-MuiLinearProgress-root .MuiLinearProgress-bar{
  background-color: #660066;
}