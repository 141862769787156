.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
   color: white; 
}
/* #mainNav .topnav .navbar-nav .nav-item .nav-link.active{
  /* background-color: #04AA6D; 
  text-decoration: underline;
  color: #fed136;
  font-weight: bold;
}
#mainNav .topnav .navbar-nav .nav-item .nav-link{
  /* background-color: #04AA6D; */
  /* text-decoration: underline;
  color: #000;
  font-weight: bold;
} */
#mainNav .topnav .navbar-nav .nav-item .nav-link:hover{
  text-decoration: underline;
  color: #fed136;
  font-weight: bold;
}

.App-link {
  color: #61dafb;
}

#appul {
  color: blue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
